import { render, staticRenderFns } from "./Help.vue?vue&type=template&id=61a42a9b&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!C:\\Users\\Messor\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61a42a9b",
  null
  
)

export default component.exports