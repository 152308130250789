import { render, staticRenderFns } from "./OrderForm.vue?vue&type=template&id=1d16b00e&"
import script from "./OrderForm.vue?vue&type=script&lang=js&"
export * from "./OrderForm.vue?vue&type=script&lang=js&"
import style0 from "./OrderForm.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!C:\\Users\\Messor\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports