import { render, staticRenderFns } from "./App.vue?vue&type=template&id=8539d336&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!C:\\Users\\Messor\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8539d336",
  null
  
)

export default component.exports